@use '/src/styles/variables';

.wrapper {
  width: 100%;
  padding: 10px;
  text-align: center;
}

.search {
  width: 50%; /* Set width to 50% */
  padding: 10px; /* Add padding */
  margin: 10px 0; /* Add margin */
  font-size: 16px; /* Increase font size */
  border: 5px solid rgb(204 204 204); /* Border style */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%); /* Shadow effect */
  transition: border 0.3s ease; /* Smooth transition */
}

.search:focus {
  border: 5px solid rgb(0 123 255); /* Change border color on focus */
  outline: none; /* Remove outline */
}

.fileStatus {
  width: 100%;
}

.fileStatus th {
  padding: 8px;
  text-align: left;
  border: 1px solid rgb(221 221 221);
}

.fileStatus td {
  padding: 8px;
  text-align: left;
  cursor: pointer;
  background-color: rgb(242 242 242);
  border: 1px solid rgb(221 221 221);
}

.fileStatus thead th {
  text-align: left;
}

.fileStatus thead tr th {
  cursor: default;
}

.fileStatus thead tr th button {
  width: 100%;
  text-align: left;
  background-color: white;
  border: 0;
}

.fileStatus thead tr th button:hover {
  cursor: pointer;
}

.fileStatus thead tr th button:after {
  float: right;
  color: variables.$color-teal;
}

.fileStatus thead tr th button.ascending:after {
  content: '\2193';
}

.fileStatus thead tr th button.descending:after {
  content: '\2191';
}
