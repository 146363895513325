@use '/src/styles/variables';

.heading {
  width: 100%;
  font-family: variables.$font-family-serif;
  font-size: variables.$font-size-xl;
}

.header {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: variables.$gap-lg;
  place-items: center end;
  padding: variables.$gap-lg;
  background-color: variables.$color-white;
  box-shadow: variables.$box-shadow;
}

.btnSignIn {
  float: right;
  padding: 5px 15px;
  margin: 5px auto;
  font-family: variables.$font-family-sans-serif;
  font-size: variables.$font-size-sm;
  color: white;
  cursor: pointer;
  background-color: variables.$color-teal;
  border: none;
  border-radius: 10px;
  opacity: 1;
}

.user {
  float: right;
  padding: 10px;
  font-family: variables.$font-family-sans-serif;
  font-size: variables.$font-size-xxs;
  font-weight: normal;
}
